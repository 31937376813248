.Spidermass {
    margin-top: 150px;
    margin-bottom: 80px;

    @include responsive($tabletSmall) {
        margin-top: get-vw(150);
        margin-bottom: get-vw(80);
    }

    .Heading {
        margin-bottom: 40px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(64);
        }
    }

    &__grid {
        display: grid;

        @include responsive($tabletSmall, max) {
            gap: 2em;
        }

        @include responsive($tabletSmall){
            grid-template-columns: repeat(3, 1fr);
            grid-gap: get-vw(20);
        }

        > .col{
            @include responsive($tabletSmall){
              grid-column: span 1;
            }
        }
    }
}