.Btn {
  @include flex(center, center, inline-flex);
  text-align: center;
  background: $orange;
  color: $white;
  padding: 12px 24px;
  margin-top: 20px;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 48px;
  transition: transform 350ms $ease;

  @include responsive($tabletSmall) {
    padding: get-vw(12) get-vw(24);
    border-radius: get-vw(48);
    margin-top: get-vw(20);
  }

  @include noTouchHover() {
    transform: scale(0.95);
  }

  &__txt {
    color: inherit;
    font-weight: bold;
    font-size: 15px;
    color: $white;

    @include responsive($tabletSmall) {
      @include font-vw(15);
    }

    html.--dark & {
      color: $base;
    }
  
  }
}