.Heading {
    display: flex;
    flex-direction: column;

    @include responsive($tabletSmall) {
        flex-direction: row;
        justify-content: space-between;

        .subtitle {
            width: space(3);
        }
    }

    &__title {
        font-family: 'saira';
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        box-sizing: content-box;
        font-size: 40px;

        @include responsive($tabletSmall) {
            @include font-vw(102);
            width: space(14);
        }
    }

    
    @include responsive($tabletSmall) {
        &__text {
            width: space(15);
        }

        &.wide-title {
            .subtitle {
                width: space(6);
            }

            .Heading__title {
                width: space(13);
            }
        }

        &.extrawide-title {
            .subtitle {
                width: space(4);
            }

            .Heading__title {
                width: space(15);
            }
        }

        &.subtitle-blue {
            .subtitle {
                color: $blue-cool;
            }
        }

        &.space-right-0 {
            .Heading__title {
                padding-right: space(0);
            }
        }

        &.space-right-1 {
            .Heading__title {
                padding-right: space(1);
            }
        }

        &.space-right-2 {
            .Heading__title {
                padding-right: space(2);
            }
        }

        &.space-right-3 {
            .Heading__title {
                padding-right: space(3);
            }
        }

        &.space-right-5 {
            .Heading__title {
                padding-right: space(5);
            }
        }
    }
}