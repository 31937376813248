.Card-spidermass {
    background: $white;
    padding: 24px 16px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include responsive($tabletSmall) {
        padding: get-vw(40) get-vw(40) get-vw(32) get-vw(40);
        border-radius: get-vw(8);
    }

    .Icon {
        width: 40px;
        margin-bottom: 16px;

        @include responsive($tabletSmall) {
            width: get-vw(80);
            margin-bottom: get-vw(88);
        }
    }

    &.--orange {
        color: $orange;
    }

    &.--blue {
        color: $blue-cool;
    }

    &.--royal {
        color: $royal;
    }

    h4 {
        font-family: 'saira';
        font-weight: 600;
        line-height: 0.9;
        text-transform: uppercase;
        margin-bottom: 16px;
        font-size: 24px;

        @include responsive($tabletSmall) {
            @include font-vw(48);
            margin-bottom: get-vw(30);
        }
    }

    p {
        line-height: 1.4;
    }
}