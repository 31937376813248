.WhoWeAre {
    margin-bottom: 80px;
    
    @include responsive($tabletSmall) {
        margin-bottom: get-vw(160);
    }

    .container {
        display: flex;
        flex-direction: column;
        
        @include responsive($tabletSmall) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .subtitle {
        @include responsive($tabletSmall) {
            width: space(7);
        }
    }

    .content {
        @include flex(start, start, flex, nowrap, column);

        @include responsive($tabletSmall) {
            width: space(15);
        }
    }

    &__link {
        background: $orange;
        color: $white !important;
        line-height: 1;
        font-weight: 600;
        box-sizing: border-box;
        display: block;
        width: fit-content;
        transition: 0.3s $ease;
        margin-top: 24px;
        padding: 12px 20px;
        font-size: 15px;
        border-radius: 4px;

        &:hover {
            text-decoration: none;
        }
        
        @include responsive($tabletSmall) {
            margin-top: get-vw(32);
            padding: get-vw(18) get-vw(24);
            border-radius: get-vw(4);
            @include font-vw(15);
        }
    }
}