.Posts {
    margin-bottom: 56px;

    @include responsive($tabletSmall) {
        margin-bottom: get-vw(108);
    }

    .Heading {
        margin-bottom: 40px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(92);
        }
    }

    .Posts__grid {
        display: grid;
        gap: 16px;
        margin-bottom: 40px;
        
        @include responsiveMinMax($mobile, $tabletSmall){
          align-items: center;
          max-width: 550px;
          margin-left: auto;
          margin-right: auto;
        }

        @include responsive($tabletSmall) {
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: get-vw(72);
        }
    }

    &.--white-bg {
        background: $white;
        padding-top: 56px;
        margin-bottom: 0;

        @include responsive($tabletSmall) {
            padding-top: get-vw(90);
        }
    }
}