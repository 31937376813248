.Contact__page {
  .Page__header {

    @include responsive($tabletSmall) {
      padding-bottom: 0;
    }

    .container {
      @include flex(flex-start, space-between);
      gap: 30px;
      flex-wrap: wrap;

      @include responsive($tabletSmall) {
        gap: space(1);
      }

      > * {
        width: 100%;
      }
    }

    h1 {
      color: $blue-cool;
      font-size: 24vw;
      line-height: 1;
      font-weight: 400;
      max-width: 80%;
      font-family: 'saira';

      @include responsive($tabletSmall) {
        max-width: space(8);
        @include font-vw(169);
      }
    }

    .intro {
      @include responsive($tabletSmall) {
        max-width: space(12);
      }
    }

    .anchor {
      min-width: 100%;

      @include responsive($tabletSmall) {
        position: absolute;
        left: space(1);
        bottom: 0;
        transform: translateY(150%);
        width: fit-content;
      }

      a {
        @include flex(center, flex-start);
        text-decoration: none!important;
        gap: 20px;

        @include responsiveMinMax($tabletSmall, $deskXL) {
          gap: get-vw(20);
        }

        .arrow__container {
          position: relative;
          @include imgRatio(1, 1, $orange);
          min-width: 45px;

          @include responsiveMinMax($tabletSmall, $deskXL) {
            min-width: get-vw(45);
          }

          &:before {
            opacity: 0.1;
            border-radius: 50%;
          }

          .Icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-90deg) ;
            width: 20px;

            @include responsiveMinMax($tabletSmall, $deskXL) {
              width: get-vw(20);
            }
  
            svg {
              stroke: $orange;
            }
          }
        }

        span {
          font-size: 28px;
          color: $orange;

          @include responsiveMinMax($tabletSmall, $deskXL) {
            @include font-vw(28);
          }
        }
      }
    }
  }
}
