.Partners {
    background: $white;
    padding: 64px 0 24px 0;
        
    @include responsive($tabletSmall) {
        padding: get-vw(160) space(1) get-vw(72) space(1);
    }

    h2 {
        text-align: center;
        font-family: 'saira';
        font-weight: 500;
        line-height: 1;
        font-size: 40px;
        margin-bottom: 24px;
        
        @include responsive($tabletSmall) {
            @include font-vw(62);
            margin-bottom: get-vw(48);
        }
    }

    &__links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 12px;

        @include responsive($mobile){
          grid-template-columns: repeat(4, 1fr);
        }

        @include responsive($tabletSmall) {
            grid-template-columns: repeat(5, 1fr);
            grid-gap: get-vw(16);
            padding: 0 space(1);
        }

        a {
            width: 100%;
            aspect-ratio: 9/5;
            position: relative;
            overflow: hidden;
            content: "";
            cursor: pointer;

            img {
                object-fit: scale-down;
                object-position: center;
                position: absolute;
                width: 100%;
                height: 100%;
                mix-blend-mode: multiply;
                filter: contrast(1);
            }

            &::before {
                position: absolute;
                inset: 0;
                transition: 0.4s $ease;
                border-radius: get-vw(4);
                background: $blue-cool;
                opacity: 0;
                transform: scale(0);
                content: "";
                display: block;
            }

            &:hover {
                &::before {
                    transform: scale(1);
                    opacity: 0.05;
                }
            }
        }
    }
}