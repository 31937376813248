.Bubble {
    position: absolute;
    z-index: -1;

    &__inner {
        aspect-ratio: 1/1;
        content: "";
        display: block;
    
        &.--blue {
            background: radial-gradient(50% 50% at 50% 50%, rgba(66, 137, 179, 0.21) 0%, rgba(66, 137, 179, 0.00) 100%);
        }
    
        &.--royal {
            background: radial-gradient(50% 50% at 50% 50%, rgba(163, 41, 106, 0.18) 0%, rgba(163, 41, 106, 0.00) 100%);
        }
    
        &.--orange {
            background: radial-gradient(50% 50% at 50% 50%, rgba(238, 130, 68, 0.13) 0%, rgba(238, 130, 68, 0.00) 100%);
        }
    }
}