.Page__hero{
  padding-top: 140px;
  margin-bottom: 32px;

  @include responsive($tabletSmall) {
    padding-top: get-vw(240);
    margin-bottom: get-vw(60);
  }

  .container {
    @include responsive($tabletSmall, max) {
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    color: $blue-cool;
    font-family: 'saira';
    line-height: 1;
    font-weight: 400;
    font-size: 60px;

    @include responsive($tabletSmall) {
      @include font-vw(169);
    }
  }

  .Btn-arrow {
    @include responsive($tabletSmall, max) {
      align-self: flex-end;
      margin-top: 32px;
    }

    @include responsive($tabletSmall) {
      position: absolute;
      inset: auto space(1) get-vw(100) auto;
    }
  }

  @include responsive($tabletSmall) {
    &.--who-are-we {
      h1 {
        width: space(9);
      }
    }

    &.--our-innovations {
      h1 {
        width: space(12);
      }
    }
  }
}