.Card-post {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
    padding: 16px;

    @include responsive($tabletSmall) {
        padding: get-vw(48);
        border-radius: get-vw(5);
    }

    .Posts__grid.patents__grid &{
        background: $grey-xl;
    }

    span {
        font-family: 'saira';
        color: $blue-cool;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 8px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(12);
            @include font-vw(17);
        }
    }

    h4 {
        line-height: 1.3;
        font-size: 20px;
        margin-bottom: 16px;

        @include responsive($tabletSmall) {
            @include font-vw(27);
            margin-bottom: get-vw(44);
        }
    }

    a {
        color: $black;
        font-weight: 600;
        width: fit-content;
        @include flex(center, center);

        @include responsive($tabletSmall) {
            @include font-vw(15);
        }

        &::before {
            position: absolute;
            inset: 0;
            content: "";
            display: block;
            background: $white;
            z-index: -1;
            transition: 0.4s $ease;

            .Posts.--white-bg & {
                background: $grey-xl;
            }
        }

        &::after {
            width: 0%;
            height: 0.2vw;
            background: $blue-cool;
            content: "";
            display: block;
            transition: 0.4s $ease;
            left: 0;
            bottom: 0;
            position: absolute;
        }

        &:hover {
            text-decoration: none;
        }

        &:hover::before {
            opacity: 0.6;
        }

        &:hover::after {
            width: 100%;
        }

        .Icon {
            width: get-vw(16);
            margin-left: get-vw(18);
        }
    }
}