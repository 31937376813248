.App {
  width: 100%;
  min-height: 100vh;
  background: $blue-cool-l;
  color: $blue-cool-d;
  position: relative;
  z-index: 10;
  transition: 0.4s $ease;
  // overflow-x: hidden;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  html.--dark & {
    background: $base;
    color: $white;
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
}

.container {
  width: 100%;
  padding: 0 20px;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }
}
