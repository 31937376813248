@import url("../../node_modules/mapbox-gl/dist/mapbox-gl.css");

.FindUs {
    padding-bottom: 30px;

    @include responsive($tabletSmall) {
        padding-bottom: get-vw(80);
    }

    .headline {
        border-top: 1px solid $grey;
        @include flex(flex-start, space-between);
        gap: 20px;
        padding: 40px 0;
        flex-wrap: wrap;

        @include responsive($tabletSmall) {
            padding: get-vw(80) 0;
        }

        .subtitle {
            margin: 0;
        }
    }

    address {
        font-size: 32px;
        line-height: 1.2;

        @include responsive($tabletSmall) {
            @include font-vw(48);
            line-height: 1.3;
            width: space(12);
        }
        
        strong {
            color: $blue-cool;
            font-weight: 500;
        }
    }

    .Map__container {
        position: relative;
        @include imgRatio(1, 1, darken($blue-cool-l, 3%));

        @include responsive($tabletSmall) {
            @include imgRatio(1320, 620, darken($blue-cool-l, 3%));
        }

        > div {
            @include cover();
        }

        .marker {
            background-image: url('../imgs/SVG/marker.svg');
            background-size: cover;
            width: 42px;
            height: 54px;
            cursor: pointer;
        }

        .mapboxgl-ctrl-attrib-inner,
        .mapboxgl-ctrl-logo {
            display: none;
        }
    }
}