@use 'sass:math';

@import "app/require/mixins";
@import "app/require/var";
@import "app/require/normalize";
@import "app/require/critical";
@import "app/require/fonts";
@import "app/require/utils";

@import "app/skeleton/layout";
@import "app/skeleton/fonts";
@import "app/skeleton/header";
@import "app/skeleton/footer";
@import "app/skeleton/forms";
@import "app/skeleton/visual";
@import "app/skeleton/icon";

@import "app/components/brand";
@import "app/components/btn";
@import "app/components/btn-arrow";
@import "app/components/anchors";
@import "app/components/links";
@import "app/components/outdated";
@import "app/components/tarteaucitron";
@import "app/components/copyright";
@import "app/components/intro";
@import "app/components/loader";
@import "app/components/nav";
@import "app/components/nav-toggle";
@import "app/components/plyr";
@import "app/components/swiper";
@import "app/components/overlay";
@import "app/components/modal";
@import "app/components/heading";
@import "app/components/btn-icon";
@import "app/components/frame-gradient";
@import "app/components/step";
@import "app/components/card-team";
@import "app/components/card-spidermass";
@import "app/components/card-application";
@import "app/components/card-post";
@import "app/components/btn-load-more";
@import "app/components/posts-progress";
@import "app/components/bubble";

@import "app/blocks/BlockPageHeader";
@import "app/blocks/BlockHomepageHero";
@import "app/blocks/BlockPageHero";
@import "app/blocks/BlockPageContent";
@import "app/blocks/BlockPageText";
@import "app/blocks/BlockBenefices";
@import "app/blocks/BlockWhoWeAre";
@import "app/blocks/BlockPrizes";
@import "app/blocks/BlockPartners";
@import "app/blocks/BlockPageCall";
@import "app/blocks/BlockSteps";
@import "app/blocks/BlockPageIntro";
@import "app/blocks/BlockTeam";
@import "app/blocks/BlockSpidermass";
@import "app/blocks/BlockApplications";
@import "app/blocks/BlockPosts";
@import "app/blocks/BlockContactForm";
@import "app/blocks/BlockFindUs";
@import "app/blocks/BlockTimeline";

@import "app/pages/page";
@import "app/pages/components";
@import "app/pages/contact";

.--pointer-none {
  pointer-events: none;
}
