.Page__text {
    padding-top: 56px;
    padding-bottom: 80px;

    @include responsive($tabletSmall) {
        padding-top: get-vw(320);
        padding-bottom: get-vw(200);
    }

    p {
        display: block;

        &:first-of-type {
            margin-bottom: 32px;

            @include responsive($tabletSmall){
                margin-bottom: get-vw(200);
            }
        }

        @include responsive($tabletSmall) {
            padding: 0 space(2);
            text-align: center;
        }

        .word__wrapper {
            overflow: hidden;
        }
    }
}