.Overlay {
  @include cover(fixed);
  display: block;
  padding: 0;
  margin: 0;
  background: $blue-cool-d;
  opacity: 0;
  pointer-events: none;
  z-index: 80;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  border: 0;
  visibility: hidden;
  transition: all 600ms $ease 0ms;

  .--show-menu &,
  .showModal & {
    visibility: visible;
    opacity: 0.8;
    pointer-events: all;
  }
}
