.Btn-load-more {
    padding: 12px 20px;
    background: $grey-blue;
    border-radius: 1000px;
    @include flex(center center);
    width: fit-content;
    cursor: pointer;
    transition: 0.4s $ease;
    margin: auto;

    @include responsive($tabletSmall) {
        padding: get-vw(18) get-vw(24);
    }

    &:hover {
        background: $blue-cool;
        color: $white;

        .Icon {
            stroke: $white;
        }
    }

    span {
        font-weight: 600;
        line-height: 1;
        font-size: 15px;

        @include responsive($tabletSmall) {
            @include font-vw(15);
        }
    }

    .Icon {
        stroke: $base;
        transform: rotate(-90deg);
        transition: 0.4s $ease;
        margin-left: 8px;
        width: 16px;

        @include responsive($tabletSmall) {
            margin-left: get-vw(8);
            width: get-vw(16);
        }
    }
}