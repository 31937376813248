.Steps {
    width: 100%;
    background: $white;
    padding-top: 100px;
    padding-bottom: 100px;

    @include responsive($tabletSmall) {
        padding-top: get-vw(120);
        padding-bottom: get-vw(120);

        .container {
            @include flex(start, space-between);
        }
    }

    &.--orange{
        padding-bottom: 0;
    }

    &.--royal {
        .container {
            @include responsive($tabletSmall) {
                flex-direction: row-reverse !important;
            }
        }
    }

    &__visual {
        position: relative;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 2/1;
        border-radius: 6px;

        @include responsive($tabletSmall) {
            width: space(9);
            height: get-vw(596);
            max-height: 90dvh;
            border-radius: get-vw(8);

            position: sticky;
            top: 2em;
        }

        img {
            @include cover();
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        padding-top: 32px;

        @include responsive($tabletSmall) {
            width: space(12);
            padding: 0 get-vw(24);
        }

        .Steps.--royal & {
            @include responsive($tabletSmall) {
                padding: 0;
            }
        }

        h3 {
            font-family: 'saira';
            line-height: 1;
            font-weight: 500;
            font-size: 32px;

            .Steps.--royal & {
                padding-left: 0;
            }

            @include responsive($tabletSmall) {
                padding-left: get-vw(36);
                margin: get-vw(8) 0 0 0;
                @include font-vw(102);
            }
        }

        .Steps__intro {
            font-family: 'open-sans';
            line-height: 1.4;
            font-size: 18px;

            @include responsive($tabletSmall) {
                margin-top: get-vw(32);
                @include font-vw(23);
            }
        }
    }
}
