.Team {
    background: $white;
    padding-top: 100px;
    padding-bottom: 24px;

    @include responsive($tabletSmall) {
        padding-top: get-vw(100);
        padding-bottom: get-vw(108);
    }

    .Heading {
        margin-bottom: 40px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(120);
        }
    }

    &__grid {
        @include grid(2, 16px, 32px);

        @include responsive($tabletSmall, max) {
            .col {
                grid-column: span 1 !important;
            }
        }

        @include responsive($tabletSmall) {
            @include grid(3, get-vw(16), get-vw(56));
        }
    }
}