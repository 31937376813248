.Timeline {
    padding: 100px 0 150px;
    background: $white;
    background: $grey-l;
    overflow: hidden;

    @include responsive($tabletSmall) {
        padding: get-vw(100) 0 get-vw(150);
    }

    .container{
        @include flex(center, center, flex, nowrap, column);
        text-align: center;
        color: $blue-cool;

        h3 {
            font: 600 30px 'saira', sans-serif; 
            margin-bottom: 0.85em;
            text-transform: uppercase;
            line-height: 1;
            
            @include responsive($tabletSmall){
                @include font-vw(32);
            }
        }

        p {
            width: space(12);
            font-family: 'open-sans';
            line-height: 1.4;
            margin-bottom: get-vw(56);
        }
    }

    &__swiper {
        position: relative;
        margin-top: 100px;
        width: 100%;

        @include responsive($tabletSmall){
          margin-top: get-vw(100);
          max-width: get-vw(940);
          margin-left: auto;
          margin-right: auto;
        }

        &.--dragging {
            .swiper-slide{
                transform: none !important;
            }
        }

        .swiper-wrapper{
            align-items: flex-start;
        }

        .swiper-slide{
            @include flex(center, center);
            flex-direction: column;
            max-width: 200px;
            padding: 1em;
            overflow: hidden;
            
            @include responsive($tabletSmall){
                max-width: calc(100% / 5);
            }

            &:nth-child(2),
            &:nth-child(6){
                color: $lavender;
                .Timeline__circle{ border-color: $lavender; }
                .Timeline__circle span{ background: $lavender; }
            }

            &:nth-child(3),
            &:nth-child(5){
                color: $eggplant;
                .Timeline__circle{ border-color: $eggplant; }
                .Timeline__circle span{ background: $eggplant; }
            }

            &:nth-child(4){
                color: $royal;
                .Timeline__circle{ border-color: $royal; }
                .Timeline__circle span{ background: $royal; }
            }
        }

        .Timeline{
            &__date{
                font: 800 30px "saira", sans-serif;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                
                @include responsive($tabletSmall){
                  @include font-vw(30);
                }
            }

            &__circle{
                @include flex(center, center);
                height: get-vw(40);
                width: get-vw(40);
                position: relative;
                border: 1px solid $blue-cool;
                background: $grey-l;
                
                @include responsive($tabletSmall, max){
                    min-width: 30px;
                    min-height: 30px;
                    margin: 1em 0;
                }

                @include responsive($tabletSmall){
                  margin: get-vw(20);
                }

                &:before{
                    @include content();
                    height: 1px; width: 100vw;
                    position: absolute;
                    left: 50%; top: 50%;
                    background: #BDD7E7;
                    z-index: -1;
                    transform: translate(-50%, -50%);
                }

                &, span{
                    border-radius: 50%;
                }

                span{
                    height: get-vw(10);
                    width: get-vw(10);
                    background: $blue-cool;

                    @include responsive($tabletSmall, max){
                      min-width: 10px;
                      min-height: 10px;
                    }
                }
            }

            &__legend{
                line-height: 1.4;

                @include responsive($tabletSmall){
                  @include font-vw(16);
                }
            }
        }
    }
}
