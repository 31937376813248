.Form {
  .--error {
    color: $error-color!important;
  }

  .row {
    @include flex(flex-start, flex-start);
    gap: 20px;
    flex-wrap: wrap;

    @include responsive($tabletSmall) {
      gap: get-vw(20);
    }

    > * {
      width: 100%;

      @include responsive($tabletSmall) {
        max-width: calc(50% - get-vw(10));
      }

      &.--full {
        @include responsive($tabletSmall) {
          min-width: 100%;
        }
      }
    }
  }

  label:not(.Checkbox) {
    display: inline-block;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.035em;
    padding: 0 0.3em;
    margin: 0 16px;
    position: relative;
    top: 0.8em;
    background: $white;

    @include responsive($tabletSmall) {
      @include font-vw(17);
      margin: 0 get-vw(16);
    }

    abbr {
      color: $blue-cool;
    }
  }

  &__group {
    position: relative;
    width: 100%;

    span.--error {
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 10px;
      padding-top: 2px;

      @include responsive($tabletSmall) {
        @include font-vw(10);
      }
    }
  }

  &__container {
    @include flex(center, flex-start);
    border: 1px solid $grey;
    padding: 0 14px;

    @include responsive($tabletSmall) {
      padding: 0 get-vw(14);
    }

    .Form__control {
      padding: 0;
      border: 0;
    }
  }

  &__control {
    width: 100%;
    min-height: 62px;
    padding: 0 24px;
    border-radius: 0;
    outline: 0;
    border: 1px solid $grey-blue;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;

    @include responsive($tabletSmall) {
      @include font-vw(16);
      min-height: get-vw(62);
      padding: 0 get-vw(24);
    }

    &:focus {
      border-color: darken($grey-blue, 5%);
    }

    .--error & {
      border-color: $error-color;
    }

    &::placeholder {
      opacity: 0.5;
    }
  }

  select.Form__control {
    line-height: 62px;

    @include responsive($tabletSmall) {
      line-height: get-vw(62);
    }
  }

  textarea.Form__control {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 30px;

    @include responsive($tabletSmall) {
      padding: get-vw(30);
      min-height: get-vw(200);
      max-height: get-vw(200);
    }
  }

  &__actions {
    min-width: 100%;
    @include flex(flex-start, flex-end);

    .Btn {
      margin-top: 0;
    }
  }

  &__output {
    min-width: 100%;
    color: $valid-color;

    p:not(:empty) {
      margin-top: 20px;

      @include responsive($tabletSmall) {
        margin-top: get-vw(20)
      }
    }
  }
}

.Radios,
.Checkboxs {
  margin-bottom: -10px;

  @include responsive($tabletSmall) {
    margin-bottom: get-vw(-10);
    @include flex(center, flex-start, inline-flex);
  }

  > * {
    margin-bottom: 10px;

    @include responsive($tabletSmall) {
      margin-right: get-vw(40);
    }
  }
}

.Checkbox {
  position: relative;
  @include flex(flex-start, flex-start, inline-flex);
  display: flex !important;
  flex-wrap: nowrap;

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked {
      & + .Checkbox__custom {
        background: $blue-cool;
        border-color: $blue-cool;

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    border: 1px solid darken($grey-blue, 5%);
    border-radius: 2px;
    margin-right: 10px;
    transition: background 240ms $easeOutQuad, border 240ms $easeOutQuad;

    @include responsive($tabletSmall) {
      margin-right: get-vw(10);
      min-height: get-vw(18);
      max-height: get-vw(18);
      height: get-vw(18);
      border-radius: get-vw(2);
    }

    .--radio & {
      border-radius: 18px;

      @include responsive($tabletSmall) {
        border-radius: get-vw(18);
      }

      &:after {
        @include content;
        width: 10px;
        height: 10px;
        background: $white;
        border-radius: 20px;
        transform: scale(0);
        transition: transform 240ms $easeOutQuad;

        @include responsive($tabletSmall) {
          width: get-vw(10);
          height: get-vw(10);
          border-radius: get-vw(20);
        }
      }
    }

    .check {
      position: relative;
      width: 66%;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          fill: transparent;
          stroke: $white;
          stroke-width: 6px;
          stroke-linecap: round;
        }
      }
    }
  }

  &__label {
    text-transform: none;
    font-size: 13px;
    letter-spacing: 0;
    font-weight: 500;

    @include responsive($tabletSmall) {
      @include font-vw(13);
    }

    a {
      font-weight: bold;
      color: $eggplant;
    }
  }
}
