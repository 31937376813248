.Icon {
  position: relative;
  @include imgRatio(1, 1);

  svg {
    @include cover();
    fill: transparent;
  }

  &[data-name="play"] {
    @include imgRatio(17, 20);
    min-width: 17px;
  }
}
