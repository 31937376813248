.Brand {
  display: block;
  position: relative;
  z-index: 10;
  pointer-events: all;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  width: 111px;
  height: 32px;
  content: "";
  transition: 0.4s $ease;

  @include responsive($tabletSmall) {
    width: get-vw(132);
    height: get-vw(38);
  }

  &:hover {
    opacity: 0.6;
  }

  svg {
    width: 111px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;

    @include responsive($tabletSmall) {
      width: get-vw(132);
      height: get-vw(38);
    }
  }

  &__white {
    fill: $white;
    opacity: 0;
    z-index: 2;
    transition: 0.4s $ease;
  }

  &__gradient {
    transition: 0.4s $ease;
  }

  &.--white {
    .Brand__white {
      opacity: 1;
    }

    .Brand__gradient {
      opacity: 0;
    }
  }

  &.--small {
    width: 92px;
    height: 26.7px;

    @include responsive($tabletSmall) {
      width: get-vw(138.2);
      height: get-vw(40);
    }

    svg {
      width: 92px;
      height: 26.7px;
  
      @include responsive($tabletSmall) {
        width: get-vw(138.2);
        height: get-vw(40);
      }
    }
  }
}
