.Frame-gradient {
    width: 100%;
    color: $white;
    background: $blue-cool;
    position: relative;
    overflow: hidden;
    @include flex(start, start, flex, nowrap, column);
    padding: 32px 24px;
    border-radius: 8px;

    @include responsive($tabletSmall) {
        padding: get-vw(64) get-vw(56);
        border-radius: get-vw(10);
    }

    &.--page-call {
        p {
            margin-bottom: 32px;
        }

        @include responsive($tabletSmall) {
            min-height: get-vw(461);

            p {
                margin-bottom: get-vw(42);
            }
        }
    }

    &.--left {
        .Frame-gradient__radial {
            &.--eggplant {
                left: auto;
                top: auto;
                right: get-vw(415);
                bottom: - get-vw(408);
            }

            &.--orange {
                left: auto;
                top: auto;
                right: get-vw(627);
                bottom: - get-vw(93);
            }
        }
    }

    .subtitle {
        color: $white;
        position: relative;
        z-index: 3;
        margin-bottom: 16px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(40);
        }
    }

    p {
        position: relative;
        z-index: 3;
        line-height: 1.2;
        font-size: 24px;

        @include responsive($tabletSmall) {
            padding-right: space(2);
            @include font-vw(48);
        }

        strong {
            font-weight: 600;
        }
    }

    a {
        margin-top: auto;
        background: $white;
        color: $royal !important;
        line-height: 1;
        font-weight: 600;
        position: relative;
        z-index: 3;
        box-sizing: border-box;
        display: block;
        width: fit-content;
        transition: 0.3s $ease;
        padding: 12px 20px;
        font-size: 15px;
        border-radius: 4px;

        &:hover {
            text-decoration: none;
        }

        @include responsive($tabletSmall) {
            padding: get-vw(18) get-vw(24);
            @include font-vw(15);
            border-radius: get-vw(4);
        }
    }
    
    &__radial {
        content: "";
        display: block;
        position: absolute;
        border-radius: 50%;
        filter: blur(get-vw(250));
        width: get-vw(1314);
        aspect-ratio: 1/1;
    
        &.--eggplant {
            background: $eggplant;
            left: get-vw(475);
            top: - get-vw(220);
            z-index: 1;
        }
    
        &.--orange {
            background: $orange;
            z-index: 2;
            left: get-vw(897);
            top: get-vw(258);
        }
    }
}