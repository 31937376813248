.Card-application {
    padding: 16px;
    height: 100%;

    @include responsive($tabletSmall) {
        padding: get-vw(40) get-vw(32) get-vw(64) get-vw(32);
    }

    &.--waikawa {
        h4 {
            color: $waikawa;
        }
    }

    &.--lavender {
        h4 {
            color: $lavender;
        }
    }

    &.--eggplant {
        h4 {
            color: $eggplant;
        }
    }

    &.--royal {
        h4 {
            color: $royal;
        }
    }

    &__visual {
        width: 100%;
        overflow: hidden;
        aspect-ratio: 3/2;
        margin-bottom: 24px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(24);
            aspect-ratio: 5/3;
        }
    }

    h4 {
        font-weight: 500;
        line-height: 0.9;
        font-family: 'saira';
        font-size: 24px;
        margin-bottom: 16px;

        @include responsive($tabletSmall) {
            @include font-vw(42);
            margin-bottom: get-vw(32);
        }
    }

    p {
        line-height: 1.4;

        @include responsive($tabletSmall) {
            padding-right: get-vw(10);
        }
    }
}