.Page__header{
  padding-top: 240px;
  padding-bottom: 40px;

  @include responsive($tabletSmall){
    padding-bottom: space(1);
  }

  .container{
    
    h1{
      @include responsive($tabletSmall){
        max-width: space(30);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 40px;
        align-self: start;
      }

      @include responsive($tabletSmall){
        align-self: end;
        margin-left: auto;
      }
    }
  }
}