.Btn-arrow {
    border-radius: 50%;
    cursor: pointer;
    z-index: 5;
    width: fit-content;
    height: fit-content;
    position: relative;
    padding: 28px;
    margin-left: auto;

    @include responsive($tabletSmall){
        padding: get-vw(50);
        margin-left: 0;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        inset: 0;
        border-radius: 50%;
        opacity: 0.1;
        background: $white;
        transition: 0.3s $ease;
    }

    &:hover {
        &::before {
            transform: scale(1.15);
        }
    }

    &.--blue {
        &::before {
            background: $blue-cool;
        }

        .Btn-arrow__icon {
            stroke: $blue-cool;
        }
    }

    &.--white {
        &::before {
            background: $white;
        }

        .Btn-arrow__icon {
            stroke: $white;
        }
    }

    &.--right {
        &:hover {
            .Btn-arrow__icon {
                left: 57.5%;
            }
        }

        .Icon {
            transform: rotate(180deg);
        }
    }

    &.--scroll {
        pointer-events: none;
        cursor: default;
        
        .Icon {
            animation: bounce 2s infinite;

            svg {
                transform: rotate(-90deg);
            }
        }
    }

    &.--left {
        &:hover {
            .Btn-arrow__icon {
                left: 42.5%;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s $ease;
        aspect-ratio: 1/1;
        width: 20px;

        @include responsive($tabletSmall){
            width: get-vw(24);
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0%);
    }
    40% {
        transform: translateY(-35%);
    }
    50% {
        transform: translateY(30%);

    }
    60% {
        transform: translateY(-20%);
    }
  }