.Homepage__hero{
  overflow: hidden;
  
  .container{
    padding-top: 240px;
    padding-bottom: 24px;
    @include flex(flex-end, flex-start);
    display: flex;
    flex-direction: column;
  
    @include responsive($tabletSmall){
      height: 100dvh;
      min-height: 580px;
      padding-top: get-vw(180);
      padding-bottom: space(1);
      flex-direction: row;
    }
    
    h1{
      font-weight: 400;
      position: relative;
      z-index: 2;
      color: $white;
      font-family: 'saira';
      font-size: 44px;
      margin-bottom: 32px;

      strong {
        font-size: 60px;
        margin-bottom: 16px;
        display: inline-block;
      }

      @include responsive($tabletSmall){
        max-width: space(18);
        @include font-vw(76);
        line-height: 1.1;
        margin-bottom: 0;

        strong {
          @include font-vw(106);
          line-height: 1.1;
          font-weight: 400;
          margin-bottom: 0;
          display: inline;
        }
      }
    }

    .Btn-arrow {
      margin-top: 24px;
      align-self: flex-end;

      @include responsive($tabletSmall){
        position: absolute;
        inset: auto space(1) get-vw(100) auto;
      }
    }

    .Homepage__hero__background{
      position: absolute;
      inset: 0;
      z-index: 1;

      .visual__container {
        height: 100%;
        width: 100%;
        overflow: visible;

        .visual {
          &::after {
            position: absolute;
            inset: 0;
            background: url('../imgs/gradient.jpg');
            background-size: cover;
            content: "";
            display: block;
            opacity: 0.5;
            z-index: 10;
            mix-blend-mode: hard-light;
          }
        }
      }
    }
  }
}