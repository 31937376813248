// SAIRA

@font-face {
  font-family: 'saira';
  font-weight: 100;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-Thin.woff2') format('woff2'),
       url('../fonts/SairaCondensed-Thin.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 200;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-ExtraLight.woff2') format('woff2'),
       url('../fonts/SairaCondensed-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-Light.woff2') format('woff2'),
       url('../fonts/SairaCondensed-Light.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-Regular.woff2') format('woff2'),
       url('../fonts/SairaCondensed-Regular.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-Medium.woff2') format('woff2'),
       url('../fonts/SairaCondensed-Medium.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-SemiBold.woff2') format('woff2'),
       url('../fonts/SairaCondensed-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-Bold.woff2') format('woff2'),
       url('../fonts/SairaCondensed-Bold.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 800;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-ExtraBold.woff2') format('woff2'),
       url('../fonts/SairaCondensed-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'saira';
  font-weight: 900;
  font-style: normal;
  font-display: block;
  src: url('../fonts/SairaCondensed-Black.woff2') format('woff2'),
       url('../fonts/SairaCondensed-Black.woff') format('woff');
}





// DOSIS

@font-face {
  font-family: 'dosis';
  font-weight: 200;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-ExtraLight.woff2') format('woff2'),
       url('../fonts/Dosis-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'dosis';
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-Light.woff2') format('woff2'),
       url('../fonts/Dosis-Light.woff') format('woff');
}

@font-face {
  font-family: 'dosis';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-Regular.woff2') format('woff2'),
       url('../fonts/Dosis-Regular.woff') format('woff');
}

@font-face {
  font-family: 'dosis';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-Medium.woff2') format('woff2'),
       url('../fonts/Dosis-Medium.woff') format('woff');
}

@font-face {
  font-family: 'dosis';
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-SemiBold.woff2') format('woff2'),
       url('../fonts/Dosis-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'dosis';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-Bold.woff2') format('woff2'),
       url('../fonts/Dosis-Bold.woff') format('woff');
}

@font-face {
  font-family: 'dosis';
  font-weight: 800;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Dosis-ExtraBold.woff2') format('woff2'),
       url('../fonts/Dosis-ExtraBold.woff') format('woff');
}





// OPEN SANS

@font-face {
  font-family: 'open-sans';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
       url('../fonts/OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'open-sans';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/OpenSans-Medium.woff2') format('woff2'),
       url('../fonts/OpenSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'open-sans';
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
       url('../fonts/OpenSans-SemiBold.woff') format('woff');
}