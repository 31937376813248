.Prizes {
    overflow-x: hidden;
    padding-bottom: 80px;
    padding-top: 0;
    overflow-y: hidden;

    @include responsive($tabletSmall, max){
        overflow: hidden;
      }

    @include responsive($tabletSmall) {
        padding-bottom: get-vw(102);
        padding-top: get-vw(12);
    }

    &__header {
        @include flex(start, space-between);
        margin-bottom: 32px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(64);
        }

        &__title {
            font-family: 'saira';
            font-weight: 500;
            line-height: 1;
            font-size: 40px;
            margin-bottom: 0;
        
            @include responsive($tabletSmall) {
                @include font-vw(102);
                width: space(18);
            }
        }

        &__buttons {
            @include flex(start, space-between);

            .prev {
                margin-right: 12px;
            }
        
            @include responsive($tabletSmall) {
                width: space(4);

                .prev {
                    margin-right: 0;
                }
            }
        }
    }

    &__swiper {
        .swiper-slide {
            background: $white;
            color: $blue-cool;
            font-family: 'saira';
            border-radius: 8px;
            padding: 24px 12px;
        
            @include responsive($tabletSmall) {
                border-radius: get-vw(8);
                padding: get-vw(48) get-vw(40);
            }

            .logo {
                margin-bottom: 24px;
                width: 90px;
                height: 40px;
                position: relative;
                overflow: hidden;
        
                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(64);
                    width: get-vw(180);
                    height: get-vw(80);
                }

                img {
                    position: absolute;
                    object-fit: contain;
                    object-position: top left;
                    width: 100%;
                    height: 100%;
                }
            }

            .date {
                font-weight: 700;
                line-height: 0.9;
                margin-bottom: 16px;
                font-size: 18px;
        
                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(28);
                    @include font-vw(20);
                    letter-spacing: get-vw(0.4);
                }
            }

            .title {
                font-size: 24px;
        
                @include responsive($tabletSmall) {
                    line-height: 0.9;
                    @include font-vw(36);
                }
            }
        }
    }
}