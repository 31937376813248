.Nav {
  &__container {
    pointer-events: all;
    position: relative;
    width: fit-content;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(get-vw(32));
      height: get-vw(1);
      background: $blue-cool;
      opacity: 0.3;
      width: 100%;
      transition: 0.3s $ease;

      .Header.--light & {
        background: $white;
      }

      body.--scrolled & {
        width: 0%;
      }
    }

    .--animating & {
      pointer-events: none;
    }

    @include responsive($menuBreakpoint, max) {
      @include flex(center, center);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: $white;
      color: $blue-cool !important;
      overflow-y: auto;
      transition: 0.3s $ease;

      @include responsive($menuBreakpoint, max) {
        transition: 0.6s $ease;
      }

      .Header.--light & {
        color: $blue-cool !important;
      }
    }

    body:not(.--show-menu) & {
      @include responsive($menuBreakpoint, max) {
        transform: translateY(-100%);
      }
    }
  }

  &__main {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    @include responsive($tabletSmall) {
      width: space(11);
      flex-direction: row;
      align-items: center;
      justify-content: start;
    }
  }

  &__item {
    text-align: center;
    margin: 16px auto;

    @include responsive($tabletSmall) {
      margin: 0 get-vw(42) 0 0;
    }

    &:last-child {
      @include responsive($tabletSmall) {
        margin-left: auto;
        margin-right: 0;
        padding-left: get-vw(32);
        position: relative;
      }

      .Nav__link {
        font-weight: 600;
      }

      &::before {
        @include responsive($tabletSmall) {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: get-vw(40);
          width: get-vw(1);
          background: $blue-cool;
          opacity: 0.3s $ease;

          .Header.--light & {
            background: $white;

            body.--scrolled & {
              background: $blue-cool !important;
            }
          }
        }
      }
    }
  }

  &__link {
    position: relative;
    transition: opacity 0.4s $ease;
    font-weight: 500;
    color: $blue-cool !important;
    font-size: 40px;

    @include responsive($tabletSmall) {
      @include font-vw(22);

      .Header.--light & {
        color: $white !important;

        body.--scrolled & {
          color: $blue-cool !important;
        }
      }
    }

    &.--active,
    &:focus,
    &:hover {
      opacity: 0.6;
    }

    &.--active {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
