.Card-team {
    @include flex(center, start, flex, nowrap, column);
    height: 100%;

    &__portrait {
        margin-bottom: 16px;
        width: 96px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 50%;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(24);
            width: get-vw(172);
        }
    }

    h4, span {
        width: fit-content;
        text-align: center;
        font-family: 'saira';
    }

    h4 {
        color: $blue-cool;
        line-height: 1.3;
        font-size: 18px;

        @include responsive($tabletSmall) {
            letter-spacing: - get-vw(0.5);
            margin-bottom: get-vw(8);
            @include font-vw(28);
        }
    }

    span {
        line-height: 1.4;
        margin-bottom: 16px;

        @include responsive($tabletSmall) {
            @include font-vw(18);
            margin-bottom: get-vw(24);
        }
    }

    &__socials {
        @include flex(center, center);
        margin-top: auto;

        .Icon {
            transition: 0.3s $ease;
            width: 20px;
            margin: 0 6px;

            @include responsive($tabletSmall) {
                width: get-vw(24);
                margin: 0 get-vw(6);
            }

            &:hover {
                cursor: pointer;
                opacity: 0.6;
            }
        }
    }
}