.Posts-progress {
  padding-bottom: 16px;
  @include flex(center, center, flex, nowrap, column);

  @include responsive($tabletSmall) {
    padding-bottom: get-vw(24);
  }

  span {
    font-weight: 600;
    line-height: 1;
    font-size: 14px;
    margin-bottom: 8px;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(16);
      @include font-vw(12);
    }
  }

  &__bar {
    position: relative;
    border-radius: 100px;
    background-color: #e6e6e6;
    width: 120px;
    height: 2px;

    @include responsive($tabletSmall) {
      height: get-vw(2);
      width: get-vw(120);
    }

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $blue-cool-d;
      width: 0;
      transition: width 0.4s $ease;
    }
  }
}
