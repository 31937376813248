.Copyright {
   @include flex(center, start);

   @include responsive($tabletSmall) {
    @include flex(center, center);
   }

   span {
    font-family: 'open-sans';
    font-size: 12px;
    line-height: 1.4;
    font-weight: 600;

    @include responsive($tabletSmall) {
      @include font-vw(12);
    }

    &.year {
      font-weight: 400;
      margin-right: 10px;

      @include responsive($tabletSmall) {
        margin-right: get-vw(24);
      }
    }
   }

   .wkn {
      fill: $white;
      margin-left: 8px;

      @include responsive($tabletSmall) {
        margin-left: get-vw(5);
      }

      &+span:not(.year) {
        font-style: italic;
        font-family: serif;
        margin-left: 8px;
  
        @include responsive($tabletSmall) {
          margin-left: get-vw(5);
        }
      }
   }

   @include noTouchHover() {
     .wkn {
       opacity: 1;

       &+span:not(.year) {
         opacity: 1;
       }
     }
   }
}
