$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;
  transform: translateY(0);
  transition: 0.4s $ease;

  .container {
    width: 100%;
    max-width: none;
    @include flex(center, space-between);
    padding-top: 30px;
    padding-bottom: 30px;
    transition: 0.4s $ease;

    @include responsive($tabletSmall) {
      padding-top: get-vw(32);
      padding-bottom: get-vw(16);
    }

    body.--scrolled & {
      background: $white;
      padding-top: 16px;
      padding-bottom: 16px;

      @include responsive($tabletSmall) {
        padding-top: get-vw(20);
        padding-bottom: get-vw(20);
      }
    }
  }

  body.--show-menu & {
    @include responsive($menuBreakpoint, max) {
      .Brand__white {
        opacity: 0 !important;
      }
    
      .Brand__gradient {
        opacity: 1 !important;
      }
    }
  }

  .Brand__white {
    opacity: 0 !important;
  }

  .Brand__gradient {
    opacity: 1 !important;
  }

  &.--light {
    .Brand__white {
      opacity: 1 !important;
    }

    .Brand__gradient {
      opacity: 0 !important;
    }
  }

  body.--scrolled & {
    &:not(.--visible) {
      transform: translateY(-100%);
    }

    .Brand__white {
      opacity: 0 !important;
    }
  
    .Brand__gradient {
      opacity: 1 !important;
    }
  }

  &.--visible {
    transform: translateY(0);
  }
}
