.Btn-icon {
    background: $white;
    aspect-ratio: 1/1;
    content: "";
    display: block;
    border-radius: get-vw(4);
    position: relative;
    transition: 0.6s $ease;
    cursor: pointer;
    width: 32px;
    margin-right: 12px;

    &:last-of-type {
        margin-right: 0;
    }

    @include responsive($tabletSmall) {
        width: get-vw(48);
        margin-right: 0;
    }

    .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        aspect-ratio: 1/1;

        @include responsive($tabletSmall) {
            width: get-vw(20);
        }

        svg {
            fill: $royal;
        }
    }

    &:hover {
        border-radius: 50%;
    }
}