.Applications {
    padding-bottom: 100px;

    @include responsive($tabletSmall) {
        padding-bottom: get-vw(100);
    }

    .Heading {
        margin-bottom: 24px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(80);
        }
    }

    &__grid {
        display: grid;
        background: $white;
        position: relative;
        z-index: 5;
        border-radius: 6px;

        @include responsive($mobile){
          grid-template-columns: repeat(2, 1fr);
        }
        
        @include responsive($tabletSmall){
            grid-template-columns: repeat(4, 1fr);
            border-radius: get-vw(8);
        }

        .col {
            border-bottom: 1px solid $grey-l;
    
            @include responsive($tabletSmall) {
                border-bottom: none;
                border-right: 1px solid $grey-l;
            }

            &:nth-child(even){
                @include responsiveMinMax($mobile, $tabletSmall){
                  border-left: 1px solid $grey-l;
                }
            }

            &:last-of-type {
                @include responsive($tabletSmall){
                    border: none;
                }
            }
        }
    }
}