#tarteaucitronRoot {

  &.tarteaucitronBeforeVisible {
    #tarteaucitronAlertBig {
      @include responsive(520px) {
        display: flex!important;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }

  #tarteaucitronBack {
    background: $blue-cool !important;
    opacity: 0.9!important;
    color: $white;
  }

  #tarteaucitronAlertBig {
    background: $blue-cool!important;
    padding: 30px!important;
    box-sizing: border-box!important;
    left: 8px!important;
    right: 8px!important;
    bottom: 8px!important;
    border-radius: 10px;
    width: auto!important;
    margin: 0!important;
    color: $white;

    @include responsive(520px) {
      max-width: 450px;
      left: 20px!important;
      right: 20px!important;
      bottom: 20px!important;
      margin: 0;
    }

    #tarteaucitronDisclaimerAlert {
      margin-right: auto!important;
      font-size: 16px;
      display: block;
      text-align: center;
      margin-bottom: 20px;
      min-width: 100%;
      max-width: 100%;
      padding: 0;

      @include responsive(520px) {
        text-align: left;
        margin-bottom: 20px;
      }
    }

    > button {
      margin: 0!important;
      background: transparent!important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      border: 1px solid $white !important;
      margin-top: 10px!important;
      padding: 12px 20px!important;
      color: $white !important;
      text-transform: uppercase;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      @include responsive(520px) {
        width: auto;
        margin-top: 0!important;
        white-space: nowrap;
      }

      &:hover {
        background: $white !important;
        color: $blue-cool!important;
      }

      &#tarteaucitronCloseAlert {
        opacity: 0.3;
        border: 1px solid $white !important;

        &:hover {
          opacity: 1;
        }
      }

      span {
        display:none!important;
      }
    }
  }

  #tarteaucitronClosePanel {
    background: $blue-cool!important;
    border-color: $blue-cool!important;
  }

  #tarteaucitronServices {
    box-shadow: none!important;

    .tarteaucitronMainLine,
    .tarteaucitronTitle button {
      background: $blue-cool!important;
      border-color: $blue-cool!important;
    }

    .tarteaucitronAllow, .tarteaucitronDeny {
      margin: 0!important;
      background: $white !important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-left: 10px!important;
      padding: 10px 20px!important;
      color: $blue-cool!important;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }

      span {
        display: none!important;
      }
    }

    ul .tarteaucitronLine.tarteaucitronIsAllowed { border-color: $valid-color!important; }
    ul .tarteaucitronLine.tarteaucitronIsDenied { border-color: $error-color!important; }

    #tarteaucitronInfo {
      background: 0!important;
    }
  }
}
