.Benefices {
    padding-bottom: 80px;

    @include responsive($tabletSmall, max){
        overflow: hidden;
      }

    @include responsive($tabletSmall) {
        padding-bottom: get-vw(160);
    }
    
    .Heading {
        margin-bottom: 40px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(160);
        }
    }

    &__cards {
        @include responsive($tabletSmall){
            @include grid(3, get-vw(18));
        }

        &__card {
            background: $white;
            @include flex(start, start, flex, nowrap, column);
            height: 100%;
            color: $blue-cool;
            padding: 16px 16px 24px 16px;
            border-radius: 4px;

            @include responsive($tabletSmall) {
                padding: get-vw(40);
                min-height: get-vw(455);
                border-radius: get-vw(8);
            }

            &.col{
                @include responsive($tabletSmall){
                    grid-column: span 1;
                }
            }

            span {
                font-weight: 700;
                font-family: 'saira';
                font-size: 24px;
                margin-bottom: 16px;

                @include responsive($tabletSmall) {
                    letter-spacing: get-vw(4);
                    margin-bottom: get-vw(64);
                    @include font-vw(20);
                }
            }

            h4 {
                font-weight: 600;
                line-height: 0.9;
                text-transform: uppercase;
                font-family: 'saira';
                font-size: 32px;
                margin-bottom: 24px;

                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(36);
                    @include font-vw(48);
                }
            }

            p {
                line-height: 1.4;
                padding-right: get-vw(8);
            }
        }
    }
}