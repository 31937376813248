.Footer {
   background: $blue-cool-d;
   color: $white;
   position: relative;
   bottom: 0;
   left: 0;
   z-index: 1;
   width: 100%;
   padding: 40px 0 24px 0;

   @include responsive($tabletSmall) {
      padding: get-vw(48) 0 get-vw(40) 0;
   }

   .subtitle {
      color: $white;
      opacity: 0.6;
      margin-top: 0;
      margin-bottom: 12px;

      @include responsive($tabletSmall) {
         margin-bottom: get-vw(12);
      }
   }

   &__slogan {
      font-family: 'saira';
      font-weight: 800;
      text-align: center;
      line-height: 0.9;
      text-transform: uppercase;
      letter-spacing: - get-vw(3);
      font-size: 40px;
      margin: 0 auto 32px auto;

      @include responsive($tabletSmall) {
         @include font-vw(146);
         margin: 0 auto get-vw(48) auto;
      }
   }

   &__content {
      position: relative;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;

      @include responsive($tabletSmall) {
         padding-bottom: get-vw(80);
         flex-direction: row;
      }

      &::after {
         display: block;
         content: "";
         height: 1px;
         width: 100%;
         left: 0;
         bottom: 0;
         opacity: 0.2;
         background: $white;
         position: absolute;

         @include responsive($tabletSmall) {
            height: get-vw(1);
         }
      }
   }

   &__about {
      margin-bottom: 16px;

      @include responsive($tabletSmall) {
         width: space(6);
         margin-right: space(3);
         margin-bottom: 0;
      }

      .Brand {
         margin-bottom: 12px;

         @include responsive($tabletSmall) {
            margin-bottom: get-vw(24);
         }
      }

      p {
         font-size: 13px;
         line-height: 1.4;
         opacity: 0.6;
         font-family: 'open-sans';

         @include responsive($tabletSmall) {
            @include font-vw(12);
         }
      }
   }

   &__address {
      margin-bottom: 16px;

      @include responsive($tabletSmall) {
         width: space(4);
         margin-right: space(1);
         margin-bottom: 0;
      }

      p {
         font-weight: 600;
         font-size: 15px;
         line-height: 1.5;
         font-family: 'open-sans';

         @include responsive($tabletSmall) {
            @include font-vw(15);
         }
      }
   }

   &__links {
      margin-bottom: 24px;

      @include responsive($tabletSmall) {
         width: space(3);
         margin-right: space(1);
         margin-bottom: 0;
      }

      ul {
         display: flex;
         flex-direction: column;

         li {
            font-weight: 600;
            font-size: 15px;
            line-height: 1;
            font-family: 'open-sans';
            margin-bottom: 12px;
            height: 16px;

            @include responsive($tabletSmall) {
               @include font-vw(15);
               margin-bottom: get-vw(16);
               height: get-vw(15);
            }

            &:last-of-type {
               margin-bottom: 0;
            }

            a {
               transition: 0.3s $ease;
               cursor: pointer;
               color: $white;

               &:hover {
                  opacity: 0.6;
                  text-decoration: none;
               }
            }
         }
      }
   }

   &__socials {
      @include responsive($tabletSmall) {
         width: space(4);
      }

      ul {
         @include flex(start, start);
         width: 100%;

         @include responsive($tabletSmall) {
            @include flex(start, space-around);
         }
      }
   }

   &__additionnal {
      padding-top: 24px;

      @include responsive($tabletSmall) {
         padding-top: get-vw(40);
         @include flex(end, space-between);
      }

      ul {
         margin-bottom: 16px;
         @include flex(start, start);
         
         @include responsive($tabletSmall) {
            margin-bottom: 0;
         }

         li {
            font-weight: 600;
            line-height: 1;
            font-family: 'open-sans';
            font-size: 13px;
            height: 13px;
            margin-right: 18px;

            @include responsive($tabletSmall) {
               @include font-vw(15);
               height: get-vw(15);
               margin-right: get-vw(24);
            }

            &:last-of-type {
               margin-right: 0;
            }

            a {
               transition: 0.3s $ease;
               cursor: pointer;
               color: $white;

               &:hover {
                  opacity: 0.6;
                  text-decoration: none;
               }
            }
         }
      }
   }
}
