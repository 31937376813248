.Step {
    position: relative;
    padding: 16px 0 16px 12px;

    @include responsive($tabletSmall) {
        padding: get-vw(40) 0 get-vw(40) get-vw(36);
    }

    &::before {
        content: "";
        display: block;
        opacity: 0.2;
        position: absolute;
        height: 100%;
        top: 0;
        width: 2px;
        left: -5px;

        @include responsive($tabletSmall) {
            width: get-vw(2);
            left: - get-vw(1);
        }

        .Steps.--orange & {
            background: $orange;
        }

        .Steps.--royal & {
            background: $royal;
        }
    }

    &:first-of-type {
        padding-top: 0;
        margin-top: 32px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(80);
        }

        &::before {
            height: calc(100% - 8px);
            top: auto;
            bottom: 0;

            @include responsive($tabletSmall) {
                height: calc(100% - get-vw(8));
            }
        }
    }

    &:last-of-type {
        padding-bottom: 0;

        &::before {
            height: 24px;
            top: 0;

            @include responsive($tabletSmall) {
                height: get-vw(48);
            }
        }
    }

    h4 {
        .Steps.--orange & {
            color: $orange;

            &::before {
                border-color: $orange;
            }
        }

        .Steps.--royal & {
            color: $royal;

            &::before {
                border-color: $royal;
            }
        }

        font-family: 'saira';
        font-weight: 500;
        line-height: 0.9;
        margin-bottom: 16px;
        position: relative;
        font-size: 22px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(32);
            @include font-vw(36);
        }

        &::before {
            content: "";
            display: block;
            border-radius: 50%;
            border-width: 2px;
            background: $white;
            position: absolute;
            border-style: solid;
            border-width: 2px;
            left: -16px;
            top: 3px;
            transform: translateX(-50%);
            padding: 6px;

            @include responsive($tabletSmall) {
                padding: get-vw(7);
                border-width: get-vw(2);
                left: - get-vw(36);
                top: get-vw(8);
                border-width: get-vw(2);
            }
        }
    }

    p {
        line-height: 1.4;
        padding-right: space(0.25);
        font-family: 'open-sans';
    }
}